const texts = {
  en: {
    disputes: {
      textEmpty: 'There are no disputes to show at this moment.',
      textTitle: 'Disputes',
      textSearch: 'Search by disputes or buyer.',
      filters: {
        startDate: 'Start date',
        endDate: 'End date'
      },
      textTab1: 'In process',
      textTab2: 'Finalized',
      tableInProcess: {
        headers: [
          'Purchase order',
          'Buyer',
          'Total',
          'Dispute creation date',
          'Status',
          'Actions'
        ]
      },
      tableFinalized: {
        headers: [
          'Purchase order',
          'Buyer',
          'Total',
          'Finalized date',
          'Status',
          'Actions'
        ]
      },
      filters: {
        textSearch: 'Search',
        startDate: 'Start date',
        endDate: 'End date',
        status: 'Status',
        // paymentStatus: 'Payment status',
        textLabelStatus: 'Status',
        textLabelStatusPaid: 'Payment Status',
        paymentStatusPaid: 'Paid',
        paymentStatusToPaid: 'To pay',
        disputeStatus: [
          {
            value: "ca16f9be-c88c-431e-a053-625f205a671d",
            name: "Started",
          },
          {
            value: "a35626a4-c14b-4f3f-85d0-beb4f60c25cb",
            name: "Analyzing",
          },
          {
            value: "69b41776-e327-4d72-8132-1428d0c57631",
            name: "Scheduled inspection",
          },
          {
            value: "db2289ae-6222-4785-9587-0276e0c21657",
            name: "Resolved",
          },
          {
            value: "44687fe1-51d9-48d8-8b7c-a9ad2a9819a7",
            name: "Cancelled",
          }
        ],
      },
    }
  },
  sp: {
    disputes: {
      textEmpty: 'No hay disputas para mostrar en este momento.',
      textTitle: 'Disputas',
      textSearch: 'Buscar por disputa o comprador.',
      filters: {
        startDate: 'Fecha inicio',
        endDate: 'Fecha final'
      },
      textTab1: 'En proceso',
      textTab2: 'Finalizadas',
      tableInProcess: {
        headers: [
          'Orden de compra',
          'Comprador',
          'Total',
          'Fecha de creación de disputa',
          'Estado',
          'Acciones']
      },
      tableFinalized: {
        headers: [
          'Orden de compra',
          'Comprador',
          'Total',
          'Fecha de finalización',
          'Estado',
          'Acciones'
        ]
      },
      filters: {
        textSearch: 'Buscar',
        startDate: 'Fecha inicio',
        endDate: 'Fecha final',
        status: 'Estado',
        // paymentStatus: 'Estado de pago',
        textLabelStatus: 'Estado',
        textLabelStatusPaid: 'Estado de pago',
        paymentStatusPaid: 'Pagado',
        paymentStatusToPaid: 'Por pagar',
        disputeStatus: [
          {
            value: "ca16f9be-c88c-431e-a053-625f205a671d",
            name: "Iniciada",
          },
          {
            value: "a35626a4-c14b-4f3f-85d0-beb4f60c25cb",
            name: "Analizando",
          },
          {
            value: "69b41776-e327-4d72-8132-1428d0c57631",
            name: "Inspección programada",
          },
          {
            value: "db2289ae-6222-4785-9587-0276e0c21657",
            name: "Resuelta",
          },
          {
            value: "44687fe1-51d9-48d8-8b7c-a9ad2a9819a7",
            name: "Cancelada",
          }
        ],
      },
    }
  }
}

export default texts;