<template>
  <div>
    <div class="display-flex">
      <div class="column-left">
        <sidebar-component />
      </div>
      <div class="column-right">
        <navbar-component />
        <div
          class="container-scroll"
          :style="{ height: `${screenHeight}px` }"
        >
          <div class="bg-container">
            <home-banner-layout :texts="texts" />
            <home-purchase-order-layout :texts="texts" />
            <home-warehouse-layout :texts="texts" />
            <home-article-layout :texts="texts" />
            <div class="push"></div>

          </div>
          <footer class="footer">
            <footer-component />
          </footer>
        </div>
      </div>
    </div>
    <sidebar-open-component />
    <article-dialog-add-layout :texts="textsArticle" />
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      //VARIABLES
      texts: "",
      textsArticle: "",
      screenHeight: 0,
    };
  },
  beforeMount() {
    this.texts = FILE.home[this.selectLanguage];
    this.textsArticle = FILE.article[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight - 64;
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.home[this.selectLanguage];
        this.textsArticle = FILE.article[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.column-left {
  width: 80px;
}

.column-right {
  width: calc(100% - 80px);
}

.container-scroll {
  overflow-y: auto;
}

.bg-container {
  width: 100%;
  min-height: 82.5%;

  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 0px;
}
.footer,
.push {
  height: 50px;
}
</style>