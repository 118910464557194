import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";
import Login from "@/views/Login";
import Home from "@/views/Home";
import Article from "@/views/Article";
import NewArticle from "@/views/NewArticle";
import EditArticle from "@/views/EditArticle";
import Signup from "@/views/Signup";
import PasswordRecovery from "@/views/PasswordRecovery";
import UserProfile from "@/views/UserProfile";
import Users from "@/views/Users";
import Warehouses from "@/views/Warehouses";
import Orders from "@/views/Orders";
import Disputes from "@/views/Disputes";
import ArticleDetail from "@/views/ArticleDetail";
import WarehouseDetail from "@/views/WarehouseDetail";
import OrderDetail from "@/views/OrderDetail";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/password-recovery",
    name: "PasswordRecovery",
    component: PasswordRecovery,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/articles",
    name: "Article",
    component: Article,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/article/add",
    name: "NewArticle",
    component: NewArticle,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/article/edit/:id",
    name: "EditArticle",
    component: EditArticle,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/warehouses",
    name: "Warehouses",
    component: Warehouses,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/disputes",
    name: "Disputes",
    component: Disputes,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/article/:id",
    name: "ArticleDetail",
    component: ArticleDetail,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/warehouse/:id",
    name: "WarehouseDetail",
    component: WarehouseDetail,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/order/:type/:id",
    name: "OrderDetail",
    component: OrderDetail,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if(to.name !== 'Orders' && to.name !== 'OrderDetail'){
      Store.commit('setTabPosition', 0)
    }
    if (Store.state.sToken) {
      next();
    } else {
      next({ path: "/" });
    }
  } else {
    if (Store.state.sToken) {
      if (
        to.name === "Login" ||
        to.name === "signup" ||
        to.name === "PasswordRecovery"
      ) {
      }
      next({ path: "/home" });

    } else {
      next();
    }
  }
});

export default router;
