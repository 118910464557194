const texts = {
  en: {
    articledetail: {
      play: 'Available',
      pause: 'Paused',
      modifyInventory: 'Modify inventory',
      titleEdit: 'Item modification',
      messageEdit: 'The item has been successfully modified.',
      dialogDelete: {
        textTitle: 'Delete item',
        textDescription: 'Are you sure to delete the item? By performing this action it cannot be reversed',
        textSubDescription: 'Note: Items attached to the vendor will go inactive, and completed orders will continue on their current intended course.',
        textConfirmation: 'I agree to delete the item and everything related to it.'
      },
      dialogEdit: {
        textTitle: 'Item creation',
        textArticleMinAmount: 'Minimum of boxes for sale',
        textArticleMeasureUnit: 'Packaging type',
        textArticleNameEs: 'Item name in Spanish',
        textArticleNameEn: 'Item name in English',
        textArticleCategoryType: 'Category type',
        textArticleCategory: 'Category',
        textArticleSubcategory: 'Subcategory',
        textArticleLotNumber: 'Lot Number',
        textArticleDescriptionEs: 'Item description in Spanish',
        textArticleDescriptionEn: 'Item description in English',
        textArticleBoxQuantity: 'Approximate items quantity ',
        textArticleBoxQuantityPlaceholder: 'Enter approximate items in inventory',
        textArticlePrice: 'Box price',
        textArticleWarehouse: 'Warehouse where the item is located',
        textArticleDisponibility: 'Available in',
        textArticlePreparationTime: 'Preparation time (Hours)',
        textArticleTemperatureWarehouse: 'Storage temperature (°F)',
        textArticleTemperatureTraslado: 'Transportation temperature (°F)',
        textArticleBoxPallet: 'Boxes per pallet',
        textPhographyTitle: 'Item photos',
        textBoxesPerPallet: 'Boxes per pallet',
        textPiecesPerBox: 'Pieces per pallet',
        textWeightPerBox: 'Weight per box (Lbs)',
        textPhographySubtitle: 'To move forward, upload at least one image.',
        textPhographyAddImage: 'Click to add an image',
        textButtonSave: 'Save',
        textButtonCancel: 'Cancel',
        textLabelImage: 'Click to add your <br /> proof of payment.'
      },
      textTitle: 'Item detail',
      textButtonReturn: 'Return',
      textMouseZoom: 'Hover over the image to zoom in.',
      textLinkButtonProductsProvider: 'View supplier items',
      textReviews: 'Califications',
      textPrice: 'Price per box',
      textAddCart: 'Add to cart',
      textTitleAboutArticle: 'Description the item',
      description: {
        text1: 'Inventory',
        text2: 'Status',
        text3: 'Storage temperature',
        text4: 'Transportation temperature',
        text5: 'Packer',
        text6: 'Packing time',
        text7: 'Item pickup availability date',
        text8: 'Warehouse location',
        text9: 'Business hours',
        text10: 'Number of boxes per pallet',
        text11: 'Pieces per pallet',
        text12: 'Weight per box',
        text13: 'Minimum items for sale'
      },
      textNoSession: 'To add items to the shopping cart you have to login.',
      buttonModify: 'Modify',
      buttonReturn: 'Return',
      buttonDelete: 'Delete'
    },
  },
  sp: {
    articledetail: {
      play: 'Disponible',
      pause: 'Pausado',
      modifyInventory: 'Modificar inventario',
      titleEdit: 'Modificación de artículo',
      messageEdit: 'El artículo a sido modificado con éxito.',
      dialogDelete: {
        textTitle: 'Eliminar artículo',
        textDescription: '¿Estás seguro de eliminar el artículo? Al realizar esta acción no podrá revertirse',
        textSubDescription: 'Nota: Los artículos adjuntos al proveedor pasarán a estado inactivo, y los pedidos concretados continuarán su curso actual previsto.',
        textConfirmation: 'Estoy de acuerdo con eliminar el artículo y todo lo relacionado a él.'
      },
      dialogEdit: {
        textTitle: 'Agregar nuevo artículo',
        textArticleMinAmount: 'Mínimo de cajas a la venta',
        textArticleMeasureUnit: 'Tipo de empaque',
        textArticleNameEs: 'Nombre del artículo en español',
        textArticleNameEn: 'Nombre del artículo en inglés',
        textArticleCategoryType: 'Tipo de categoría',
        textArticleCategory: 'Categoría',
        textArticleSubcategory: 'Subcategoría',
        textArticleLotNumber: 'Número de lote',
        textArticleDescriptionEs: 'Descripción del artículo en español',
        textArticleDescriptionEn: 'Descripción del artículo en inglés',
        textArticleBoxQuantity: 'Cantidad aproximada de artículos en inventario',
        textArticleBoxQuantityPlaceholder: 'Ingrese aproximada de artículos en inventario',
        textArticlePrice: 'Precio de caja',
        textArticleWarehouse: 'Almacén donde se encuentra el artículo',
        textArticleDisponibility: 'Disponible en',
        textArticlePreparationTime: 'Tiempo de preparación (Horas)',
        textArticleTemperatureWarehouse: 'Temperatura del almacén (°F)',
        textArticleTemperatureTraslado: 'Temperatura de traslado (°F)',
        textArticleBoxPallet: 'Cajas por pallet',
        textPhographyTitle: 'Fotografías del artículo',
        textPhographySubtitle: 'Para avanzar, subir por lo menos una imagen.',
        textPhographyAddImage: 'Da click para añadir una imagen',
        textBoxesPerPallet: 'Cajas por pallet',
        textPiecesPerBox: 'Piezas por pallet',
        textWeightPerBox: 'Peso por caja (Lbs)',
        textButtonSave: 'Guardar',
        textButtonCancel: 'Cancelar',
        textLabelImage: 'Da click para agregar tu <br /> comprobante de pago.'
      },
      textTitle: 'Detalle de artículo',
      textButtonReturn: 'Regresar',
      textMouseZoom: 'Pasa el mouse encima de la imagen para aplicar zoom.',
      textLinkButtonProductsProvider: 'Ver artículos del proveedor',
      textReviews: 'Calificaciones',
      textPrice: 'Precio por caja',
      textAddCart: 'Añadir al carrito',
      textTitleAboutArticle: 'Descripción del artículo',
      description: {
        text1: 'Inventario',
        text2: 'Estado',
        text3: 'Temperatura en almacén',
        text4: 'Temperatura en traslado',
        text5: 'Empacadora',
        text6: 'Tiempo de empaque',
        text7: 'Fecha de disponibilidad de recolección del artículo',
        text8: 'Ubicación de almacén',
        text9: 'Horario de atención',
        text10: 'Número de cajas por pallet',
        text11: 'Piezas por pallet',
        text12: 'Peso por caja (Lbs)',
        text13: 'Mínimo de artículos a la venta',
      },
      textNoSession: 'Para agregar artículos al carrito de compras tiene que iniciar sesión.',
      buttonModify: 'Modificar',
      buttonReturn: 'Regresar',
      buttonDelete: 'Eliminar'
    }
  }
};

export default texts;