const texts = {
  en: {
    acceptorder: {
      textTitle: 'Confirm purchase order',
      description: 'Before confirming the purchase order, please state the freight price (Without VAT) ORIGIN - BORDER',
      costFlete: 'Freight cost',
      buttons: {
        confirm: 'Confirm',
        cancel: 'Cancel',
      }
    }
  },
  sp: {
    acceptorder: {
      textTitle: 'Confirmar orden de compra',
      description: 'Antes de confirmar la orden de compra, favor de colocar el precio de flete (Sin IVA) ORIGEN - FRONTERA',
      costFlete: 'Costo de flete',
      buttons: {
        confirm: 'Confirmar',
        cancel: 'Cancelar',
      }
    }
  }
}
export default texts;