const texts = {
  en: {
    disputesdetail: {
      textTitle: 'Dispute detail',
      requested: 'Requested on',
      textOrder: 'Purchase order',
      textTotalOrder: 'Order total',
      textMouseZoom: 'Hover over the image to zoom in.',
      detailDispute: {
        textTitle: 'Dispute detail',
        textId: 'Dispute ID',
        textDate: 'Creation date',
        textCategory: 'Category of the dispute',
        textSubcategory: 'Subcategory of the dispute',
        textManager: 'Dispute manager',
      },
      descriptionDispute: {
        textTitle: 'Description of the dispute'
      },
      resolutionDispute: {
        textTitle: 'Resolution',
        sNotResolutionMessage: "Esta disputa no ha sido resuelta aún."
      }
    }
  },
  sp: {
    disputesdetail: {
      textTitle: 'Detalle de disputa',
      requested: 'Creada el',
      textOrder: 'Orden de compra',
      textTotalOrder: 'Total de la orden',
      textMouseZoom: 'Pasa el mouse encima de la imagen para aplicar zoom.',
      detailDispute: {
        textTitle: 'Detalle de la disputa',
        textId: 'ID de la disputa',
        textDate: 'Fecha de creación',
        textCategory: 'Categoría de la disputa',
        textSubcategory: 'Subcategoría de la disputa',
        textManager: 'Encargado de la disputa',
      },
      descriptionDispute: {
        textTitle: 'Descripción de la disputa'
      },
      resolutionDispute: {
        textTitle: 'Resolución',
        sNotResolutionMessage: "Esta disputa no ha sido resuelta aún."
      }
    }
  }
}

export default texts;