const texts = {
  en: {
    inventory: {
      txtLabelHoraEstimada: 'Estimated time(hh:mm)​',
      txtLabelHoraEstimadaPlaceholder: 'Select approximate time.',
      textResultInventory: 'Your inventory total will update to: ',
      textTitle: 'Modify inventory',
      textActual: 'Current inventory',
      textNumLot: 'Lot number',
      textQuantiy: 'Amount of ',
      options: [
        {
          text: 'Add',
          value: true
        },
        {
          text: 'Remove',
          value: false
        }
      ],
      textDisponibility: 'Estimated date(mm/dd/yyyy)',
      textDisponibilityPlaceholder: 'Select aproximate date',
      buttons: {
        cancel: 'Cancel',
        add: 'Add',
        save: 'Save',
        return: 'Return',
      },
    },
  },
  sp: {
    inventory: {
      txtLabelHoraEstimada: 'Hora estimada(hh:mm)',
      txtLabelHoraEstimadaPlaceholder: 'Seleccione hora aproximada.',
      textResultInventory: 'El total de tu inventario se actualizara a: ',
      textTitle: 'Modificar inventario',
      textActual: 'Inventario actual: ',
      textNumLot: 'Número del lote',
      textQuantiy: 'Cantidad de ',
      options: [
        {
          text: 'Añadir',
          value: true,
        },
        {
          text: 'Quitar',
          value: false
        }
      ],
      textDisponibility: 'Fecha estimada(mm/dd/aaaa)',
      textDisponibilityPlaceholder: 'Seleccione fecha aproximada.',
      buttons: {
        cancel: 'Cancelar',
        add: 'Agregar',
        save: 'Guardar',
        return: 'Regresar',
      },
    },
  }
}
export default texts;