const texts = {
  en: {
    users: {
      textEmpty: 'There are currently no users related to this company.',
      textUserStatus: 'User status',
      textTitle: 'Users',
      textFalse: 'Inactive',
      textTrue: 'Active',
      textSearch: 'Search by name or email.',
      textUserState: 'User status',
      textButtonAdd: 'Add user',
      dialogDelete: {
        textTitle: 'user'
      },
      table: {
        headers: ['Name', 'Email', 'Phone numer', 'Creation date', 'Status', 'Actions']
      },
      dialogAddUser: {
        textTitle: 'New user',
        textUserName: 'Name',
        textUserLastName: 'Last name',
        textUserEmail: 'Email',
        textUserPosition: 'Position',
        textUserPhoneNumber: 'Phone number',
        textButtonCancel: 'Cancel',
        textButtonAdd: 'Add'
      },
      dialogEditUser: {
        textTitle: 'Modify user',
        textUserName: 'Name',
        textUserLastName: 'Last name',
        textUserEmail: 'Email',
        textUserPosition: 'Position',
        textUserPhoneNumber: 'Phone number',
        textButtonCancel: 'Cancel',
        textButtonAdd: 'Save changes'
      },
      dialogDetailUser: {
        textTitle: 'User profile',
        textButtonReturn: 'Back',
        textButtonChangeState: 'Change status',
        textButtonModify: 'Modify',
        textActive: 'User active',
        textInactive: 'User inactive'
      }
    }
  },
  sp: {
    users: {
      textEmpty: 'Actualmente no hay usuarios relacionados con esta empresa.',
      textUserStatus: 'Estado del usuario',
      textTitle: 'Usuarios',
      textFalse: 'Inactivo',
      textTrue: 'Activo',
      textSearch: 'Buscar por nombre o correo electrónico.',
      textUserState: 'Estado del usuario',
      textButtonAdd: 'Añadir usuario',
      dialogDelete: {
        textTitle: 'usuario'
      },
      table: {
        headers: ['Nombre', 'Correo electrónico', 'Teléfono', 'Fecha de creación', 'Estado', 'Acciones']
      },
      dialogAddUser: {
        textTitle: 'Nuevo usuario',
        textUserName: 'Nombre',
        textUserLastName: 'Apellido',
        textUserEmail: 'Correo electrónico',
        textUserPosition: 'Posición',
        textUserPhoneNumber: 'Teléfono',
        textButtonCancel: 'Cancelar',
        textButtonAdd: 'Añadir'
      },
      dialogEditUser: {
        textTitle: 'Modificar usuario',
        textUserName: 'Nombre',
        textUserLastName: 'Apellido',
        textUserEmail: 'Correo electrónico',
        textUserPosition: 'Posición',
        textUserPhoneNumber: 'Teléfono',
        textButtonCancel: 'Cancelar',
        textButtonAdd: 'Guardar cambios'
      },
      dialogDetailUser: {
        textTitle: 'Perfil de usuario',
        textButtonReturn: 'Regresar',
        textButtonChangeState: 'Cambiar estado',
        textButtonModify: 'Modificar',
        textActive: 'Usuario activo',
        textInactive: 'Usuario inactivo'
      }
    }
  }
}

export default texts;