import Vue from "vue";
import Vuex from "vuex";
import PersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sToken: "",
    sFullName: "",
    sUserId: "",
    sEnterpriseId: "",
    permissions: null || localStorage.getItem("user-permissions"),

    language: localStorage.getItem("language") || "en",
    isManager: false,
    isVerified: false,
    isLogged: false,
    refresh: false,
    showSidebar: false,
    profileDialogChangePassword: false,
    profileDialogEdit: false,
    profileDialogEditArr: [],
    profileDialogEditEnterprise: false,
    profileDialogEditEnterpriseArr: [],
    profileDialogAddDocument: false,
    dialogDeleteActive: false,
    dialogDeleteTitle: "",
    dialogDeleteApi: "",
    dialogDeleteRedirect: "",
    iCurrentPage: 1,
    sSearch: "",
    startDate: null,
    endDate: null,
    userStatus: "",
    sFilter: "",
    sCategoryIdFilter: "",
    sSubcategoryIdFilter: "",
    articleDialogAdd: false,
    dialogAddUser: false,
    dialogDetailUser: false,
    dialogDetailUserArr: [],
    dialogEditUserArr: [],
    dialogEditUser: false,
    dialogAddWarehouse: false,
    dialogDeleteConfirm: false,
    dialogDeleteConfirmObj: {},
    articleDetailDialogEdit: false,
    articleDetailDialogEditArr: [],
    tabWarehouse: 0,
    dialogDetailDispute: false,
    dialogDetailDisputeArr: [],
    dialogUpdateStatusActive: false,
    dialogUpdateStatusArr: [],
    paymentStatus: null,
    orderStatus: [],
    galleryActive: false,
    galleryImageSelected: 0,
    gallerAllImages: [],
    sortArticlePrice: "",
    sortArticleAlpha: "",
    sortArticleRating: [],
    disputeStatus: [],
    disputePaymentStatus: null,
    activeInventory: false,
    arrInventory: [],
    acceptOrderActive: false,
    acceptPurchaseOrderId: "",
    acceptSalesOrderId: "",
    rejectOrderActive: false,
    rejectPurchaseOrderId: "",
    rejectSalesOrderId: "",
    sTabPosition: 0,
  },
  mutations: {
    // --- is admin --- //
    setManager(state, value) {
      state.isManager = value;
    },
    setVerified(state, value) {
      state.isVerified = value;
    },
    setToken(state, value) {
      state.sToken = value;
    },
    setFullName(state, value) {
      state.sFullName = value;
    },
    setUserId(state, value) {
      state.sUserId = value;
    },
    setEnterpriseId(state, value) {
      state.sEnterpriseId = value;
    },
    toggle_language(state, value) {
      state.language = value;
      localStorage.setItem("language", value);
    },
    setIsLogged(state, value) {
      state.isLogged = value;
    },
    refresher(state, item) {
      state.refresh = item;
    },
    setShowSidebar(state, value) {
      state.showSidebar = value;
    },
    setProfileDialogChangePassword(state, value) {
      state.profileDialogChangePassword = value;
    },
    setProfileDialogEdit(state, value) {
      state.profileDialogEdit = value.active;
      state.profileDialogEditArr = value.arr;
    },
    setProfileDialogEditEnterprise(state, value) {
      state.profileDialogEditEnterprise = value.active;
      state.profileDialogEditEnterpriseArr = value.arr;
    },
    setProfileDialogAddDocument(state, value) {
      state.profileDialogAddDocument = value;
    },
    setDialogDelete(state, value) {
      state.dialogDeleteActive = value.active;
      state.dialogDeleteTitle = value.title;
      state.dialogDeleteApi = value.api;
      state.dialogDeleteRedirect = value.redirect;
    },
    // --- search by text --- //
    setSearch(state, value) {
      state.sSearch = value;
    },

    setStartDate(state, value) {
      state.startDate = value;
    },

    setEndDate(state, value) {
      state.endDate = value;
    },
    setUserStatus(state, value) {
      state.userStatus = value;
    },
    setFilter(state, value) {
      state.sFilter = value;
    },
    setCategoryIdFilter(state, value) {
      state.sCategoryIdFilter = value;
    },
    setSubcategoryIdFilter(state, value) {
      state.sSubcategoryIdFilter = value;
    },
    setArticleDialogAdd(state, value) {
      state.articleDialogAdd = value;
    },
    setDialogAddUser(state, value) {
      state.dialogAddUser = value;
    },
    setDialogDetailUser(state, value) {
      state.dialogDetailUserArr = value.arr;
      state.dialogDetailUser = value.active;
    },
    setDialogEditUser(state, value) {
      state.dialogEditUserArr = value.arr;
      state.dialogEditUser = value.active;
    },
    setDialogAddWarehouse(state, value) {
      state.dialogAddWarehouse = value;
    },
    setDialogDeleteConfirm(state, value) {
      state.dialogDeleteConfirm = value.active;
      state.dialogDeleteConfirmObj = value.obj;
    },
    setArticleDetailDialogEdit(state, value) {
      state.articleDetailDialogEdit = value.active;
      state.articleDetailDialogEditArr = value.arr;
    },
    setTabWarehouse(state, value) {
      state.tabWarehouse = value;
    },
    setDialogDetailDispute(state, value) {
      state.dialogDetailDispute = value.active;
      state.dialogDetailDisputeArr = value.arr;
    },
    setDialogUpdateStatus(state, value) {
      state.dialogUpdateStatusActive = value.active;
      state.dialogUpdateStatusArr = value.arr;
    },
    setPaymentStatus(state, value) {
      state.paymentStatus = value;
    },
    setOrderStatus(state, value) {
      state.orderStatus = value;
    },
    setGallery(state, value) {
      state.galleryActive = value.active;
      state.galleryImageSelected = value.imageSelected;
      state.gallerAllImages = value.imagesArr;
    },
    setGalleryImageSelected(state, value) {
      state.galleryImageSelected = value;
    },
    setSortArticlePrice(state, value) {
      state.sortArticlePrice = value;
    },
    setSortArticleAlpha(state, value) {
      state.sortArticleAlpha = value;
    },
    setSortArticleRating(state, value) {
      state.sortArticleRating = value;
    },
    setDisputeStatus(state, value) {
      state.disputeStatus = value;
    },
    setDisputePaymentStatus(state, value) {
      state.disputePaymentStatus = value;
    },
    setDialogInventory(state, value) {
      state.activeInventory = value.active;
      state.arrInventory = value.arr;
    },
    setDialogAcceptOrder(state, value) {
      state.acceptOrderActive = value.active;
      state.acceptPurchaseOrderId = value.purchaseOrderId;
      state.acceptSalesOrderId = value.idSalesOrder;
    },
    setDialogRejectOrder(state, value) {
      state.rejectOrderActive = value.active;
      state.rejectPurchaseOrderId = value.purchaseOrderId;
      state.rejectSalesOrderId = value.idSalesOrder;
    },
    setTabPosition(state, item) {
      state.sTabPosition = item;
    },
  },
  actions: {},
  modules: {},
  plugins: [PersistedState()],
});
