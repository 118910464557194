<template>
  <v-app>
    <div id="app">
      <router-view />
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      //VARIABLES
    };
  },
};
</script>

<style>
/* import fonts */
@font-face {
  font-family: "montserrat-black";
  src: url("../src/assets/fonts/Montserrat-Black.ttf") format("opentype");
}

@font-face {
  font-family: "montserrat-bold";
  src: url("../src/assets/fonts/Montserrat-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "montserrat-light";
  src: url("../src/assets/fonts/Montserrat-Light.ttf") format("opentype");
}

@font-face {
  font-family: "montserrat-medium";
  src: url("../src/assets/fonts/Montserrat-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "montserrat-regular";
  src: url("../src/assets/fonts/Montserrat-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "montserrat-semibold";
  src: url("../src/assets/fonts/Montserrat-SemiBold.ttf") format("opentype");
}

/* global styles */
html,
body {
  margin: 0;
  font-family: "monserrat-regular", sans-serif;
  position: relative;
}

.v-application {
  font-family: "monserrat-regular", sans-serif;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.cursor-pointer {
  cursor: pointer;
}

/* use fonts in the project through css classes */
.mon-black {
  font-family: "montserrat-black", sans-serif;
}

.mon-bold {
  font-family: "montserrat-bold", sans-serif;
}

.mon-light {
  font-family: "montserrat-light", sans-serif;
}

.mon-medium {
  font-family: "montserrat-medium", sans-serif;
}

.mon-regular {
  font-family: "montserrat-regular", sans-serif;
}

.mon-semibold {
  font-family: "montserrat-semibold", sans-serif;
}

/* inputs global style */
.global-inputs .v-text-field__details {
  display: none;
}

.global-inputs .v-input__slot {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 9px !important;
  font-size: 13px;
}

.global-inputs fieldset {
  border: 1px solid #e5e5e5 !important;
}

.global-inputs input {
  color: #000000 !important;
  letter-spacing: 0px !important;
}

.global-inputs .v-select__slot .v-label,
.global-inputs .v-text-field__slot .v-label {
  font-size: 13px;
}

/* add scrolls */
scrollbar {
  overflow: auto;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #f2f2f4;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #151d28;
}

@media (max-width: 960px) {
  ::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px transparent;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

/* remove scrolls */
/* ::-webkit-scrollbar {
  display: none;
} */

/* scrollbar {
  -moz-appearance: none !important;
} */

/* styles datetime picker */
.vue__time-picker {
  width: 100% !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  height: 40px;
}

.vue__time-picker input.display-time:focus-visible {
  outline: none !important;
}

.vue__time-picker input.display-time {
  border: none !important;
}

.vue__time-picker .controls {
  display: none !important;
}

.vue__time-picker input.display-time {
  font-size: 13px !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 9px !important;
  padding: 10px !important;
  font-family: "montserrat-regular", sans-serif !important;
}

.vue__time-picker .dropdown {
  left: auto !important;
  right: 0px !important;
  height: 14em !important;
  top: -54px !important;
}

.vue__time-picker .dropdown .hint {
  color: #000000 !important;
  font-family: "montserrat-medium", sans-serif !important;
  font-size: 14px !important;
}

.vue__time-picker .dropdown ul li {
  font-size: 12px !important;
  font-family: "montserrat-regular", sans-serif !important;
}

.vue__time-picker .dropdown .select-list {
  height: 14em !important;
  width: 100% !important;
}

.content-fieldset {
  position: relative;
}

.content-fieldset p {
  position: absolute;
  top: -7px;
  left: 10px;
  z-index: 1;
  font-size: 10px;
  font-family: "montserrat-regular", sans-serif;
  color: rgba(0, 0, 0, 0.6);
}

/* empty content */
.empty-content {
  padding: 15px 0px;
}

.empty-image {
  width: 70px;
  opacity: 0.4;
}

.empty-text {
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #6e7d94;
  margin-bottom: 0px !important;
}

.empty-link {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0071ff;
  opacity: 0.4;
  margin-bottom: 0px !important;
}

.empty-link:hover {
  cursor: pointer;
  text-decoration: underline;
  opacity: 1;
}

/* global buttons */
.button-secondary {
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #283c4d;
  border-radius: 10px !important;
  opacity: 1 !important;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #283c4d !important;
  height: 40px !important;
  text-transform: initial !important;
  background-color: transparent !important;
}

.button-primary {
  height: 40px !important;
  text-transform: initial !important;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px !important;
  opacity: 1 !important;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #283c4d !important;
}

.button-tertiary {
  height: 40px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #f50017 !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px !important;
  color: #f50017 !important;
  text-transform: initial !important;
  background-color: transparent !important;
}

.button-primary:disabled,
.button-secondary:disabled,
.button-tertiary:disabled {
  opacity: 0.5;
  cursor: no-drop !important;
  pointer-events: all !important;
}

/* pagination styles */
.pagination-styles .theme--light.v-pagination .v-pagination__item--active {
  color: #283c4d !important;
  font-size: 13px !important;
}

.pagination-styles .v-pagination__item,
.pagination-styles .v-pagination__navigation {
  height: 30px !important;
  width: 30px !important;
  font-size: 13px !important;
}

.pagination-styles {
  width: 310px !important;
}

/* input money */
.label-input-curreny {
  position: absolute;
  background-color: transparent;
  top: -6px;
  background-color: transparent !important;
  height: 3px;
  left: 12px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: normal;
}

.input-currency {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  font-size: 13px;
  width: 100%;
  border: 1px solid #e5e5e5;
  color: #000000;
  letter-spacing: 0px;
  padding: 10px 12px;
  text-align: right;
}

.input-currency:focus-visible {
  outline: none;
}

.global-content-backgorund-img {
  position: relative !important;
}
.global-button-edit-item {
  position: absolute !important;
  right: 0px !important;
  bottom: 5px !important;
  min-width: 30px !important;
  height: 30px !important;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  padding: 0px !important;
  border-radius: 10px !important;
}

/* remove sidebar of all content  */

@media (max-width: 960px) {
  .column-left {
    display: none !important;
    width: 0px !important;
  }
  .column-right {
    width: 100% !important;
  }
}
</style>