import home from "@/texts/home";
import login from "@/texts/login";
import signup from "@/texts/signup";
import passwordrecovery from "@/texts/passwordrecovery";
import sidebar from "@/texts/sidebar";
import footer from "@/texts/footer";
import userprofile from "@/texts/userprofile";
import deletee from "@/texts/deletee";
import article from "@/texts/article";
import users from "@/texts/users";
import warehouses from "@/texts/warehouses"
import orders from "@/texts/orders"
import disputes from "@/texts/disputes"
import articledetail from "@/texts/articledetail"
import deleteconfirm from "@/texts/deleteconfirm"
import warehousedetail from "@/texts/warehousedetail"
import disputesdetail from "@/texts/disputesdetail"
import inventory from "@/texts/inventory"
import acceptorder from "@/texts/acceptorder"
import rejectorder from "@/texts/rejectorder"
import global from "@/texts/global"

export const texts = {
  home,
  login,
  signup,
  passwordrecovery,
  sidebar,
  footer,
  userprofile,
  deletee,
  article,
  users,
  warehouses,
  orders,
  disputes,
  articledetail,
  deleteconfirm,
  warehousedetail,
  disputesdetail,
  inventory,
  acceptorder,
  rejectorder,
  global,
}