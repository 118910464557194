var texts = {
  en: {
    passwordrecovery: {
      textTitle: 'Recover password',
      textButtonReturn: 'Back',
      textEmail: 'Email',
      textButtonForgot: 'send recovery',
      textDescription: 'Enter the email associated with your PROPLAT account to continue.'
    }
  },
  sp: {
    passwordrecovery: {
      textTitle: 'Recuperar contraseña',
      textButtonReturn: 'Regresar',
      textEmail: 'Correo electrónico',
      textButtonForgot: 'Enviar recuperación',
      textDescription: 'Ingresa el correo electrónico asociado a tu cuenta PROPLAT para continuar.'
    }
  }
};

export default texts;