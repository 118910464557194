<template>
  <div>
    <v-container
      class="container-padding"
      fluid
    >
      <v-row>
        <v-col
          class="content-padding"
          cols="12"
          md="5"
        >
          <div
            :style="{ height: screenHeight + 'px', overflow: 'auto' }"
            class="container-form"
          >
            <select-language-component />
            <signup-form-layout :texts="texts"></signup-form-layout>
          </div>
        </v-col>
        <v-col
          class="pl-0 pb-0 hidden-md"
          cols="12"
          md="7"
        >
          <div :style="{ height: screenHeight + 'px' }">
            <signup-background-layout></signup-background-layout>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SelectLanguageComponent from "@/components/SelectLanguage";

export default {
  name: "SignupView",
  data() {
    return {
      //VARIABLES
      texts: "",
      screenHeight: 0,
    };
  },
  beforeMount() {
    this.texts = FILE.signup[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight;
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.signup[this.selectLanguage];
      }
    },
  },
  components: {
    SelectLanguageComponent,
  },
};
</script>

<style scoped>
.container-form {
  padding: 50px;
  position: relative;
}

.content-padding {
  padding-right: 0px;
  padding-bottom: 0px;
}

.container-padding {
  padding: 0px;
}

@media (max-width: 960px) {
  .hidden-md {
    display: none;
  }
}

@media (max-width: 600px) {
  .container-form {
    padding: 10px;
    padding-top: 50px;
  }
  .content-padding,
  .container-padding {
    padding: 12px !important;
  }
}
</style>