var texts = {
  en: {
    login: {
      textTitle: 'Login',
      email: 'Email',
      password: 'Password',
      buttonLogin: 'Login',
      buttonForgot: 'Problems logging in?',
      buttonSignup: 'Create account',
      textProplat: 'PROPLAT'
    }
  },
  sp: {
    login: {
      textTitle: 'Inicio de sesión',
      email: 'Correo electrónico',
      password: 'Contraseña',
      buttonLogin: 'Iniciar sesión',
      buttonForgot: '¿Problemas para iniciar sesión?',
      buttonSignup: 'Crear cuenta',
      textProplat: 'PROPLAT'
    }
  }
};

export default texts;