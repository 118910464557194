var texts = {
  en: {
    signup: {
      textTitle: 'Vendor sign up',
      textEnterpriseName: 'Enterprise name',
      textEnterpriseCountry: 'Enterprise country',
      textEnterpriseState: 'Enterprise state',
      textName: 'Name',
      textLastName: 'Last name',
      textEmail: 'Email',
      textPhone: 'Phone number',
      textPassword: 'Password',
      textConfirmPassword: 'Confirm password',
      textButtonCancel: 'Cancel',
      textButtonRegister: 'Sign up',
      textButtonReturn: 'Back',
      textSamePassword: 'Both passwords must be the same.'
    }
  },
  sp: {
    signup: {
      textTitle: 'Registro de proveedor',
      textEnterpriseName: 'Nombre de la empresa',
      textEnterpriseCountry: 'País de la empresa',
      textEnterpriseState: 'Estado de la empresa',
      textName: 'Nombre',
      textLastName: 'Apellido',
      textEmail: 'Correo electrónico',
      textPhone: 'Teléfono',
      textPassword: 'Contraseña',
      textConfirmPassword: 'Confirmar contraseña',
      textButtonCancel: 'Cancelar',
      textButtonRegister: 'Registrar',
      textButtonReturn: 'Regresar',
      textSamePassword: 'Ambas contraseñas deben de ser iguales.'
    }
  }
};

export default texts;