<template>
  <div>
    <div class="display-flex">
      <div class="column-left">
        <sidebar-component />
      </div>
      <div class="column-right">
        <navbar-component />
        <div
          class="container-scroll"
          :style="{ height: `${screenHeight}px` }"
        >
          <div class="bg-container">
            <warehouse-detail-searchbar-layout
              :texts="texts"
              :arr="arr"
            />
            <warehouse-detail-content-layout
              :texts="texts"
              :arr="arr"
            />
             <div class="push"></div>
          </div>
           <footer class="footer">
            <footer-component />
          </footer>
        </div>
      </div>
    </div>
    <sidebar-open-component />
    <delete-component />
    <delete-confirm-component />
  </div>
</template>

<script>
export default {
  name: "WarehouseDetailView",
  data() {
    return {
      //VARIABLES
      texts: "",
      screenHeight: 0,
      arr: {},
    };
  },
  beforeMount() {
    this.texts = FILE.warehousedetail[this.selectLanguage];
    this.getWarehouse();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight - 64;
    },
    getWarehouse: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/warehouses/${this.$route.params.id}`,
        config
      )
        .then((response) => {
          this.arr = response.data.results;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.warehousedetail[this.selectLanguage];
        this.getWarehouse();
      }
    },
  },
};
</script>

<style scoped>
.column-left {
  width: 80px;
}

.column-right {
  width: calc(100% - 80px);
}

.container-scroll {
  overflow-y: auto;
  background: #f2f2f4 0% 0% no-repeat padding-box;
}

.bg-container {
  width: 100%;
  min-height: 82.5%;

  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 0px;
}
.footer,
.push {
  height: 50px;
}
</style>