const texts = {
  en: {
    warehouses: {
      textEmpty: 'There are currently no warehouses related to this company.',
      textTitle: 'Warehouses',
      textButtonAdd: 'Add warehouse',
      textButtonView: 'View detail',
      dialogAdd: {
        textTitle: 'Add warehouse',
        warehouseName: 'Warehouse name',
        wahrehouseAddress: 'Street address',
        wahrehouseAddressPlaceholder: 'Ej: Plaza Chroma, Ricardo Margain 315...',
        warehouseCountry: 'Country',
        warehouseState: 'State',
        warehouseCity: 'City',
        warehousePostalCode: 'Postal code',
        warehousePhoneNumber: 'Warehouse phone number',
        warehouseEmail: 'Warehouse email',
        reponsableWarehouse: 'Warehouse manager',
        warehouseLatitude: 'Latitude',
        warehouseLongitude: 'Longitude',
        warehouseComments: 'Comments',
        warehouseShippingLimitTime: 'What is the daily cut-off time to dispatch items?',
        warehouseShippingLimitTimePlaceholder: 'Daily cut-off time for dispatch',
        buttonClose: 'Close',
        buttonAdd: 'Add warehouse',
        businesshours:"Business hours",
        schedules: {
          textMonday: 'Monday',
          textTuesday: 'Tuesday',
          textWednesday: 'Wednesday',
          textThursday: 'Thursday',
          textFriday: 'Friday',
          textSaturday: 'Saturday',
          textSunday: 'Sunday',
          textFrom: 'From',
          textTo: 'To',
        }
      }
    }
  },
  sp: {
    warehouses: {
      textEmpty: 'Actualmente no hay almacenes relacionados con esta empresa.',
      textTitle: 'Almacenes',
      textButtonAdd: 'Añadir almacén',
      textButtonView: 'Ver detalle',
      dialogAdd: {
        textTitle: 'Añadir almacén',
        warehouseName: 'Nombre del almacén',
        warehousePlaceholderName: 'Asigne un nombre para almacén',
        wahrehouseAddress: 'Dirección',
        wahrehouseAddressPlaceholder: 'Ej: Plaza Chroma, Ricardo Margain 315...',
        warehouseCountry: 'País',
        warehousePlaceholderCountry: 'Seleccione país',
        warehouseState: 'Estado',
        warehousePlaceholderState: 'Seleccione estado',
        warehouseCity: 'Ciudad',
        warehousePlaceholderCity: 'Seleccione ciudad',
        warehousePostalCode: 'Codigo postal',
        warehousePlaceholderPostalCode: 'Ingrese código postal',
        warehousePhoneNumber: 'Teléfono del almacén',
        warehousePlaceholderPhoneNumber: 'Número de contacto del almacén.',
        warehouseEmail: 'Correo electronico del almacén',
        reponsableWarehouse: 'Responsable del almacén',
        warehouseLatitude: 'Latitud',
        warehouseLongitude: 'Longitud',
        warehouseComments: 'Comentarios',
        warehouseShippingLimitTime: '¿Cuál es la hora límite diaria para despachar artículos?',
        warehouseShippingLimitTimePlaceholder: 'Hora límite diaria para despachar',
        buttonClose: 'Cerrar',
        buttonAdd: 'Añadir almacén',
        businesshours:"Horario de atención",
        schedules: {
          textMonday: 'Lunes',
          textTuesday: 'Martes',
          textWednesday: 'Miercoles',
          textThursday: 'Jueves',
          textFriday: 'Viernes',
          textSaturday: 'Sábado',
          textSunday: 'Domingo',
          textFrom: 'Desde',
          textTo: 'Hasta',
        }
      }
    }
  }
}

export default texts;