var texts = {
  en: {
    warehousedetail: {
      textemptyhorarios: 'Unregistred schedule.',
      buttons: {
        return: 'Back',
        save: 'Save',
        cancel: 'Cancel',
        modify: 'Modify',
        delete: 'Delete'
      },
      information: {
        text1: 'Warehouse name',
        text2: 'Warehouse email',
        text3: 'Warehouse phone',
        text4: 'Warehouse manager',
        text5: 'Shipping time limit',
        text6: 'Street address',
        text7: 'City',
        text8: 'Zip code',
        text9: 'Country',
        text10: 'State',
        textMonday: 'Monday',
        textTuesday: 'Tuesday',
        textWednesday: 'Wednesday',
        textThursday: 'Thursday',
        textFriday: 'Friday',
        textSaturday: 'Saturday',
        textSunday: 'Sunday',
      },
      dialogAddDocument: {
        textTitle: 'Add document',
        textDocumentName: 'Document name',
        textDocumentLabelFile: 'Click to add document',
        textButtonClose: 'Close',
        textButtonAdd: 'Save'
      },
      tableAttachments: {
        headers: ['Name', 'Document', 'Actions']
      },
      buttonDelete: 'Delete',
      dialogDelete: {
        textTitle: 'Delete warehouse',
        textDescription: 'Are you sure to delete the warehouse? By performing this action it cannot be reversed',
        textSubDescription: 'Note: Items attached to the warehouse will go inactive, and completed orders will continue on their current intended course.',
        textConfirmation: 'I agree to delete the warehouses and everything related to it.'
      },
      dialogAdd: {
        textTitle: 'Add warehouse',
        warehouseName: 'Warehouse name',
        wahrehouseAddress: 'Street address',
        warehouseCountry: 'Country',
        warehouseState: 'State',
        warehouseCity: 'City',
        warehousePostalCode: 'Postal code',
        warehousePhoneNumber: 'Warehouse phone number',
        warehouseEmail: 'Warehouse email',
        reponsableWarehouse: 'Warehouse manager',
        warehouseLatitude: 'Latitude',
        warehouseLongitude: 'Longitude',
        warehouseComments: 'Comments',
        warehouseShippingLimitTime: 'Shipping limit time',
        buttonClose: 'Close',
        buttonAdd: 'Add warehouse',
        schedules: {
          textMonday: 'Monday',
          textTuesday: 'Tuesday',
          textWednesday: 'Wednesday',
          textThursday: 'Thursday',
          textFriday: 'Friday',
          textSaturday: 'Saturday',
          textSunday: 'Sunday',
          textFrom: 'From',
          textTo: 'To',
        }
      },
      deleteTextDocument: 'warehouse document',
      textTitleDelete: 'warehouse',
      delete: 'Delete',
      wiewDetail: 'View detail',
      emptyText: 'There are no registered warehouses.',
      attachments: {
        dragFile: 'Drag a file',
        selectFile: 'Select a file',
        or: 'or',
        textNoDocuments: 'Sin documentos',
        buttonClose: 'Cerrar',
        buttonAddWarehouse: 'Crear almacén',
        buttonAddDocument: 'Add document',
      },
      photographs: {
        textName: 'Name',
        textActions: 'Actions',
        textNoDocuments: 'Without documents',
        buttonClose: 'Close',
        buttonAddWarehouse: 'Add warehouse',
      },
      textTitle: 'Warehouse',
      buttonAddWarehouse: 'Add warehouse',
      addWarehouse: {
        generalInformation: 'General information',
        attachments: 'Attachments',
        images: 'Photos',
        buttonReturn: 'Back',
        textTitle: 'New warehouse',
        warehouseName: {
          label: 'Warehouse name',
          placeholder: 'Warehouse name',
        },
        wahrehouseAddress: {
          label: 'Street address',
          placeholder: 'Street address',
        },
        warehouseCountry: {
          label: 'Country',
          placeholder: 'Country',
        },
        warehouseState: {
          label: 'State',
          placeholder: 'State',
        },
        warehouseCity: {
          label: 'City',
          placeholder: 'City',
        },
        warehousePostalCode: {
          label: 'Postal code',
          placeholder: 'Postal code',
        },
        warehousePhoneNumber: {
          label: 'Warehouse phone number',
          placeholder: 'Warehouse phone number',
        },
        warehouseEmail: {
          label: 'Warehouse email',
          placeholder: 'Warehouse email',
        },
        reponsableWarehouse: {
          label: 'Warehouse manager',
          placeholder: 'Warehouse manager',
        },
        warehouseLatitude: {
          label: 'Latitude',
          placeholder: 'Latitude',
        },
        warehouseLongitude: {
          label: 'Longitude',
          placeholder: 'Longitude',
        },
        warehouseComments: {
          label: 'Comments',
          placeholder: 'Comments',
        },
        buttonClose: 'Back',
        buttonAddWarehouse: 'Add warehouse',
      },
      buttonSaveChangeWarehouse: 'Save',
    }
  },
  sp: {
    warehousedetail: {
      textemptyhorarios: 'Horario no registrado.',
      buttons: {
        return: 'Regresar',
        save: 'Guardar',
        cancel: 'Cancelar',
        modify: 'Modificar',
        delete: 'Eliminar'
      },
      information: {
        text1: 'Nombre del almacén',
        text2: 'Correo eléctronico del almácen',
        text3: 'Télefono del almácen',
        text4: 'Responsable del almacén',
        text5: 'Tiempo límite de envío',
        text6: 'Dirección',
        text7: 'Ciudad',
        text8: 'Código postal',
        text9: 'País',
        text10: 'Estado',
        textMonday: 'Lunes',
        textTuesday: 'Martes',
        textWednesday: 'Miercoles',
        textThursday: 'Jueves',
        textFriday: 'Viernes',
        textSaturday: 'Sábado',
        textSunday: 'Domingo',
      },
      dialogAddDocument: {
        textTitle: 'Añadir documento',
        textDocumentName: 'Nombre del documento',
        textDocumentLabelFile: 'Da click para agregar el documento',
        textButtonClose: 'Cerrar',
        textButtonAdd: 'Guardar'
      },
      tableAttachments: {
        headers: ['Nombre', 'Documento', 'Acciones']
      },
      buttonDelete: 'Eliminar',
      dialogDelete: {
        textTitle: 'Eliminar almacén',
        textDescription: '¿Estás seguro de eliminar el almacén? Al realizar esta acción no podrá revertirse',
        textSubDescription: 'Nota: Los artículos adjuntos al almacén pasarán a estado inactivo, y los pedidos concretados continuarán su curso actual previsto.',
        textConfirmation: 'Estoy de acuerdo con eliminar el almacén y todo lo relacionado a él.'
      },
      dialogAdd: {
        textTitle: 'Añadir almacén',
        warehouseName: 'Nombre del almacén',
        wahrehouseAddress: 'Dirección',
        warehouseCountry: 'País',
        warehouseState: 'Estado',
        warehouseCity: 'Ciudad',
        warehousePostalCode: 'Codigo postal',
        warehousePhoneNumber: 'Teléfono del almacén',
        warehouseEmail: 'Correo electronico del almacén',
        reponsableWarehouse: 'Responsable del almacén',
        warehouseLatitude: 'Latitud',
        warehouseLongitude: 'Longitud',
        warehouseComments: 'Comentarios',
        warehouseShippingLimitTime: 'Tiempo límite de envío',
        buttonClose: 'Cerrar',
        buttonAdd: 'Añadir almacén',
        schedules: {
          textMonday: 'Lunes',
          textTuesday: 'Martes',
          textWednesday: 'Miercoles',
          textThursday: 'Jueves',
          textFriday: 'Viernes',
          textSaturday: 'Sábado',
          textSunday: 'Domingo',
          textFrom: 'Desde',
          textTo: 'Hasta',
        }
      },
      deleteTextDocument: 'documento de almacén',
      textTitleDelete: 'almacén',
      delete: 'Eliminar',
      wiewDetail: 'Ver detalle',
      emptyText: 'Aún no hay almacenes registrados.',
      attachments: {
        buttonAddDocument: 'Añadir documento',
        textName: 'Nombre',
        textActions: 'Acciones',
        textNoDocuments: 'Sin documentos',
        buttonClose: 'Cerrar',
        buttonAddWarehouse: 'Crear almacén',
      },
      photographs: {
        dragFile: 'Arrastra un archivo',
        selectFile: 'Selecciona un archivo',
        or: 'ó',
        textNoDocuments: 'Sin documentos',
        buttonClose: 'Cerrar',
        buttonAddWarehouse: 'Crear almacén',
      },
      textTitle: 'Almacenes',
      buttonAddWarehouse: 'Añadir almacén',
      addWarehouse: {
        generalInformation: 'Información general',
        attachments: 'Documentos',
        images: 'Imagenes',
        buttonReturn: 'Regresar',
        textTitle: 'Nuevo almacén',
        warehouseName: {
          label: 'Nombre del almacén',
          placeholder: 'Nombre del almacén',
        },
        wahrehouseAddress: {
          label: 'Dirección',
          placeholder: 'Dirección',
        },
        warehouseCountry: {
          label: 'País',
          placeholder: 'País',
        },
        warehouseState: {
          label: 'Estado',
          placeholder: 'Estado',
        },
        warehouseCity: {
          label: 'Ciudad',
          placeholder: 'Ciudad',
        },
        warehousePostalCode: {
          label: 'Código postal',
          placeholder: 'Código postal',
        },
        warehousePhoneNumber: {
          label: 'Teléfono del almacén',
          placeholder: 'Teléfono del almacén',
        },
        warehouseEmail: {
          label: 'Correo del almacén',
          placeholder: 'Correo del almacén',
        },
        reponsableWarehouse: {
          label: 'Responsable del almacén',
          placeholder: 'Responsable del almacén',
        },
        warehouseLatitude: {
          label: 'Latitud',
          placeholder: 'Latitud',
        },
        warehouseLongitude: {
          label: 'Longitud',
          placeholder: 'Longitud',
        },
        warehouseComments: {
          label: 'Comentarios',
          placeholder: 'Comentarios',
        },
        buttonClose: 'Regresar',
        buttonAddWarehouse: 'Crear almacén',
      },
      buttonSaveChangeWarehouse: 'Guardar',
    }
  }
};

export default texts;