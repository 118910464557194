const texts = {
  en: {
    rejectorder: {
      title: 'Reject purchase order',
      description: 'You are about to reject a purchase order. Are you sure you want to continue with the action?',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Confirm'
      }
    }
  },
  sp: {
    rejectorder: {
      title: 'Rechazar orden de compra',
      description: 'Está a punto de rechazar una orden de compra ¿Seguro que desea continuar con la acción?',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Confirmar'
      }
    }
  }
}

export default texts;