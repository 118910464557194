import Vue from 'vue';

// --- general components --- //
Vue.component('NavbarComponent', () => import('@/components/Navbar'));
Vue.component('SidebarComponent', () => import('@/components/Sidebar'));
Vue.component('SidebarOpenComponent', () => import('@/components/SidebarOpen'));
Vue.component('FooterComponent', () => import('@/components/Footer'));
Vue.component('DeleteComponent', () => import('@/components/Delete'));
Vue.component('DeleteConfirmComponent', () => import('@/components/DeleteConfirm'));
Vue.component('ZoomImageComponent', () => import('@/components/ZoomImage'));
Vue.component('GalleryComponent', () => import('@/components/Gallery'));
Vue.component('CloseDialogComponent', () => import('@/components/CloseDialog'));
Vue.component('EmptyTextComponent', () => import('@/components/EmptyText'));
Vue.component('GmapMap', () => import('vue2-google-maps'));
Vue.component('VueTimepicker', () => import('vue2-timepicker'));
Vue.component('InputCurrencyGlobal', () => import('@/components/input_currency_global/InputCurrencyGlobal.vue'))


// --- login components --- //
Vue.component('LoginFormLayout', () => import('@/layouts/Login/form'));
Vue.component('LoginBackgroundLayout', () => import('@/layouts/Login/background'));

// --- signup components --- //
Vue.component('SignupFormLayout', () => import('@/layouts/Signup/form'));
Vue.component('SignupBackgroundLayout', () => import('@/layouts/Signup/background'));

// --- password recovery components --- //
Vue.component('PasswordRecoveryFormLayout', () => import('@/layouts/PasswordRecovery/form'));
Vue.component('PasswordRecoveryBackgroundLayout', () => import('@/layouts/PasswordRecovery/background'));

// --- password recovery components --- //
Vue.component('HomeBannerLayout', () => import('@/layouts/Home/banner'));
Vue.component('HomePurchaseOrderLayout', () => import('@/layouts/Home/purchaseOrder'));
Vue.component('HomeWarehouseLayout', () => import('@/layouts/Home/warehouse'));
Vue.component('HomeArticleLayout', () => import('@/layouts/Home/article'));

// --- user profile components --- //
Vue.component('UserProfileContentLayout', () => import('@/layouts/UserProfile/content'));
Vue.component('UserProfileContentTabOneLayout', () => import('@/layouts/UserProfile/contentTabOne'));
Vue.component('UserProfileContentTabTwoLayout', () => import('@/layouts/UserProfile/contentTabTwo'));
Vue.component('UserProfileContentTabThreeLayout', () => import('@/layouts/UserProfile/contentTabThree'));
Vue.component('UserProfileDialogChangePasswordLayout', () => import('@/layouts/UserProfile/dialogChangePassword'));
Vue.component('UserProfileDialogEditLayout', () => import('@/layouts/UserProfile/dialogEdit'));
Vue.component('UserProfileDialogEditEnterpriseLayout', () => import('@/layouts/UserProfile/dialogEditEnterprise'));
Vue.component('UserProfileAddDocumentLayout', () => import('@/layouts/UserProfile/addDocument'));

// --- article components --- //
Vue.component('ArticleSearchbarLayout', () => import('@/layouts/Article/searchbar'));
Vue.component('ArticleContentLayout', () => import('@/layouts/Article/content'));
Vue.component('ArticleDialogAddLayout', () => import('@/layouts/Article/dialogAdd'));
Vue.component('ArticleFilterLayout', () => import('@/layouts/Article/filter'));

// --- new article components --- //
Vue.component('NewArticleHeaderLayout', () => import('@/layouts/NewArticle/header'));
Vue.component('NewArticleContentLayout', () => import('@/layouts/NewArticle/content'));

// --- edit article components --- //
Vue.component('EditArticleHeaderLayout', () => import('@/layouts/EditArticle/header'));
Vue.component('EditArticleContentLayout', () => import('@/layouts/EditArticle/content'));

// --- users components --- //
Vue.component('UsersSearchbarLayout', () => import('@/layouts/Users/searchbar'));
Vue.component('UsersContentLayout', () => import('@/layouts/Users/content'));
Vue.component('UsersDialogAddUserLayout', () => import('@/layouts/Users/dialogAddUser'));
Vue.component('UsersDialogDetailUserLayout', () => import('@/layouts/Users/dialogDetailUser'));
Vue.component('UsersDialogEditUserLayout', () => import('@/layouts/Users/dialogEditUser'));

// --- warehouses components --- //
Vue.component('WarehousesContentLayout', () => import('@/layouts/Warehouses/content'));
Vue.component('WarehousesDialogAddLayout', () => import('@/layouts/Warehouses/dialogAdd'));


// --- orders components --- //
Vue.component('OrdersContentLayout', () => import('@/layouts/Orders/content'));
Vue.component('OrdersTabContentOneLayout', () => import('@/layouts/Orders/tabContentOne'));
Vue.component('OrdersTabContentTwoLayout', () => import('@/layouts/Orders/tabContentTwo'));
Vue.component('OrdersTabContentThreeLayout', () => import('@/layouts/Orders/tabContentThree'));
Vue.component('OrdersFilterLayout', () => import('@/layouts/Orders/filter'));

// --- disputes components --- //
Vue.component('DisputesContentLayout', () => import('@/layouts/Disputes/content'));
Vue.component('DisputesTabContentOneLayout', () => import('@/layouts/Disputes/tabContentOne'));
Vue.component('DisputesTabContentTwoLayout', () => import('@/layouts/Disputes/tabContentTwo'));
Vue.component('DisputesDialogDetailLayout', () => import('@/layouts/Disputes/dialogDetail'));
Vue.component('DisputesFilterLayout', () => import('@/layouts/Disputes/filter'));

// --- article detail components --- //
Vue.component('ArticleDetailContentLayout', () => import('@/layouts/ArticleDetail/content'));
Vue.component('ArticleDetailDialogEditLayout', () => import('@/layouts/ArticleDetail/dialogEdit'));
Vue.component('ArticleDetailModifyInventoryLayout', () => import('@/layouts/ArticleDetail/modifyInventory'));

// --- warehouses detail components --- //
Vue.component('WarehouseDetailSearchbarLayout', () => import('@/layouts/WarehouseDetail/searchbar'));
Vue.component('WarehouseDetailContentLayout', () => import('@/layouts/WarehouseDetail/content'));
Vue.component('WarehouseDetailContentTabOneLayout', () => import('@/layouts/WarehouseDetail/contentTabOne'));
Vue.component('WarehouseDetailContentTabTwoLayout', () => import('@/layouts/WarehouseDetail/contentTabTwo'));
Vue.component('WarehouseDetailContentTabThreeLayout', () => import('@/layouts/WarehouseDetail/contentTabThree'));

// --- orders detail components --- //
Vue.component('OrderDetailContentLayout', () => import('@/layouts/OrderDetail/content'));
Vue.component('OrderDetailAcceptLayout', () => import('@/layouts/OrderDetail/accept'));
Vue.component('OrderDetailUpdateStatusLayout', () => import('@/layouts/OrderDetail/updateStatus'));
Vue.component('OrderDetailAcceptOrderLayout', () => import('@/layouts/OrderDetail/acceptOrder'));
Vue.component('OrderDetailRejectOrderLayout', () => import('@/layouts/OrderDetail/rejectOrder'));