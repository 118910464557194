const texts = {
  en: {
    footer: {
      textDescription: 'the platform where you can trade your agricultural items directly, quickly, safely and without intermediaries.',
      conditions: 'Terms and conditions',
      reserved: 'All rights reserved PROPLAT',
      powered: 'Powered by Sofex Technologies'
    }
  },
  sp: {
    footer: {
      textDescription: 'La plataforma donde puedes comerciar PRODUCE de forma directa, rápida, segura y sin intermediarios.',
      conditions: 'Términos y condiciones',
      reserved: 'Todos los derechos reservados PROPLAT',
      powered: 'Creado por Sofex Technologies'
    }
  }
}

export default texts;