<template>
  <div class="content-button-language">
    <v-btn
      icon
      elevation="2"
      @click="setLanguage('sp')"
    >
      <img
        width="25px"
        src="@/assets/icons/mx.svg"
      >
    </v-btn>
    <v-btn
      icon
      elevation="2"
      @click="setLanguage('en')"
      class="mt-4"
    >
      <img
        width="25px"
        src="@/assets/icons/eu.svg"
      >
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    setLanguage: function (e) {
      this.$store.commit("toggle_language", e);
    },
  },
  computed: {
    language: function () {
      return this.$store.state.language;
    },
  },
  watch: {},
};
</script>

<style scoped>
.content-button-language {
  position: absolute;
  top: 10px;
  right: 10px;
  display: grid;
  flex-wrap: wrap;
}
</style>