var texts = {
  en: {
    deleteconfirm: {
      textButtonDelete: 'Delete',
      textButtonCancel: 'Cancel',
    }
  },
  sp: {
    deleteconfirm: {
      textButtonDelete: 'Eliminar',
      textButtonCancel: 'Cancelar',
    }
  }
};

export default texts;