var texts = {
  en: {
    sidebar: {
      textHome: 'Home',
      textArticle: 'My items',
      textPurchaseOrder: 'Purchase orders',
      textHistorialPurchaseOrder: 'Purchase order history',
      textHistorialDisputes: 'Disputes history',
      textWarehouses: 'Warehouses',
      textUsers: 'Users',
      textProfile: 'User profile',
      textEnLang: 'English',
      textSpLang: 'Español',
      textCloseSession: 'Log out'
    }
  },
  sp: {
    sidebar: {
      textHome: 'Inicio',
      textArticle: 'Mis artículos',
      textPurchaseOrder: 'Órdenes de compra',
      textHistorialPurchaseOrder: 'Historial o. de compra',
      textHistorialDisputes: 'Historial de disputas',
      textWarehouses: 'Almacenes',
      textUsers: 'Usuarios',
      textProfile: 'Perfil de usuario',
      textEnLang: 'English',
      textSpLang: 'Español',
      textCloseSession: 'Cerrar sesión'
    }
  }
};

export default texts;