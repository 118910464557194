var texts = {
  en: {
    delete: {
      textTitle: 'Delete ',
      textButtonDelete: 'Delete',
      textButtonCancel: 'Cancel',
      textDescription: 'This action is irreversible <br/> Do you want to continue?'
    }
  },
  sp: {
    delete: {
      textTitle: 'Eliminar ',
      textButtonDelete: 'Eliminar',
      textButtonCancel: 'Cancelar',
      textDescription: 'Está acción es irreversible <br/> ¿Desea continuar?'
    }
  }
};

export default texts;