const texts = {
  en: {
    userprofile: {
      textHeaderVerify: 'Verify',
      textYesVerified: 'Your enterprise is already verified by PROPLAT.',
      textNoVerified: 'Your enterprise is not yet verified by PROPLAT.',
      textEmpty: 'There are currently no documents related to this enterprise.',
      textTitle: 'User profile',
      tabUserData: 'User data',
      tabEnterpriseData: 'Enterprise data',
      tabDocuments: 'Attachments',
      tab1: {
        table: {
          textHeader1: 'Name',
          textHeader2: 'Last name',
          textHeader3: 'Email',
          textHeader4: 'Position',
          textHeader5: 'Phone number'
        },
        textButtonCancel: 'Cancel',
        textChangePassword: 'Change password',
        textButtonModify: 'Modify',
        textButtonClose: 'Close',
        textButtonSave: 'Save'
      },
      tab2: {
        table: {
          textHeader1: 'Name',
          textHeader2: 'Address',
          textHeader3: 'Email',
          textHeader4: 'Phone Number',
          textHeader5: 'City',
          textHeader6: 'State',
          textHeader7: 'Country',
          textHeader8: 'Postal code',
          textHeader9: 'Longitude',
          textHeader10: 'Latitude',
          textHeader11: 'Description',
          textHeader12: 'Tax Payer Id',
        },
        textButtonCancel: 'Cancel',
        textChangePassword: 'Change password',
        textButtonModify: 'Modify',
        textButtonClose: 'Close',
        textButtonSave: 'Save',
        buttons: {
          cancel: 'Cancel',
          return: 'Back',
          delete: 'Delete',
          save: 'Save',
          modify: 'Modify'
        },
      },
      dialogChangePassword: {
        textTitle: 'Password reset.',
        currenPassword: 'Current password',
        newPassword: 'New password',
        confirmNewPassword: 'Confirm new password',
        buttonCancel: 'Cancel',
        buttonSave: 'Save',
      },
      dialogEdit: {
        textTitle: 'Edit user data',
        textHeader1: 'Name',
        textHeader2: 'Last name',
        textHeader3: 'Email',
        textHeader4: 'Position',
        textHeader5: 'Phone number',
        buttonCancel: 'Cancel',
        buttonSave: 'Save',
      },
      dialogEditEnterprise: {
        textTitle: 'Edit enterprise details',
        textHeader1: 'Name',
        textHeader2: 'Address',
        textHeader3: 'Email',
        textHeader4: 'Phone Number',
        textHeader5: 'City',
        textHeader6: 'State',
        textHeader7: 'Country',
        textHeader8: 'Postal code',
        textHeader9: 'Longitude',
        textHeader10: 'Latitude',
        textHeader11: 'Description',
        textHeader12: 'Tax Payer Id',
        buttonCancel: 'Cancelar',
        buttonSave: 'Guardar',
      },
      attachments: {
        table: {
          headers: ['Name', 'Enterpise documents', 'Actions']
        },
        buttonAddDocument: 'Add document',
        selectTypeDocument: 'Select document',
        textFile: 'Select your file by clicking here',
        buttonCancel: 'Cancel',
        buttonAdd: 'Add',
        textTitleDialogDelete: 'document'
      }
    },
  },
  sp: {
    userprofile: {
      textHeaderVerify: 'Verificada',
      textYesVerified: 'Tu empresa ya está verificada por PROPLAT.',
      textNoVerified: 'Tu empresa aun no esta verificada por PROPLAT.',
      textEmpty: 'Actualmente no hay documentos relacionados con esta empresa.',
      textTitle: 'Perfil de usuario',
      tabUserData: 'Datos del usuario',
      tabEnterpriseData: 'Datos de la empresa',
      tabDocuments: 'Documentos adjuntos',
      tab1: {
        table: {
          textHeader1: 'Nombre',
          textHeader2: 'Apellido',
          textHeader3: 'Correo electrónico',
          textHeader4: 'Posición',
          textHeader5: 'Teléfono'
        },
        textButtonCancel: 'Cancelar',
        textChangePassword: 'Cambiar contraseña',
        textButtonModify: 'Modificar',
        textButtonClose: 'Cerrar',
        textButtonSave: 'Guardar'
      },
      tab2: {
        table: {
          textHeader1: 'Nombre',
          textHeader2: 'Dirección',
          textHeader3: 'Correo electrónico',
          textHeader4: 'Teléfono',
          textHeader5: 'Ciudad',
          textHeader6: 'Estado',
          textHeader7: 'País',
          textHeader8: 'Código postal',
          textHeader9: 'Longitud',
          textHeader10: 'Latitud',
          textHeader11: 'Descripción',
          textHeader12: 'RFC',
        },
        textButtonCancel: 'Cancelar',
        textChangePassword: 'Cambiar contraseña',
        textButtonModify: 'Modificar',
        textButtonClose: 'Cerrar',
        textButtonSave: 'Guardar',
        buttons: {
          cancel: 'Cancelar',
          return: 'Regresar',
          delete: 'Eliminar',
          save: 'Guardar',
          modify: 'Modificar'
        },
      },
      dialogChangePassword: {
        textTitle: 'Cambio de contraseña',
        currenPassword: 'Contraseña actual',
        newPassword: 'Nueva contraseña',
        confirmNewPassword: 'Confirmar nueva contraseña',
        buttonCancel: 'Cancelar',
        buttonSave: 'Guardar',
      },
      dialogEdit: {
        textTitle: 'Editar datos del usuario',
        textHeader1: 'Nombre',
        textHeader2: 'Apellido',
        textHeader3: 'Correo electrónico',
        textHeader4: 'Posición',
        textHeader5: 'Teléfono',
        buttonCancel: 'Cancelar',
        buttonSave: 'Guardar',
      },
      dialogEditEnterprise: {
        textTitle: 'Editar datos de la empresa',
        textHeader1: 'Nombre',
        textHeader2: 'Dirección',
        textHeader3: 'Correo electrónico',
        textHeader4: 'Teléfono',
        textHeader5: 'Ciudad',
        textHeader6: 'Estado',
        textHeader7: 'País',
        textHeader8: 'Código postal',
        textHeader9: 'Longitud',
        textHeader10: 'Latitud',
        textHeader11: 'Descripción',
        textHeader12: 'RFC',
        buttonCancel: 'Cancelar',
        buttonSave: 'Guardar',
      },
      attachments: {
        table: {
          headers: ['Nombre', 'Documentos de la empresa', 'Acciones']
        },
        buttonAddDocument: 'Agregar documento',
        selectTypeDocument: 'Seleccionar documento',
        textFile: 'Selecciona tu archivo dando click aquí',
        buttonCancel: 'Cancelar',
        buttonAdd: 'Añadir',
        textTitleDialogDelete: 'documento'
      }
    },
  }
}

export default texts;