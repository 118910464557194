var texts = {
  en: {
    home: {
      isVerifiedText: 'Your company has not been verified, contact PROPLAT.',
      withoutWarehouse: 'Before creating a item, you must first sign up a warehouse.',
      banner: {
        welcome: 'Welcome to PROPLAT',
        description: 'The platform where you can trade your <br/> agricultural items directly, quickly, safely and without intermediaries.'
      },
      purchaseOrder: {
        textTitle: 'Latest purchase orders',
        textAllOrders: 'See all orders',
        empty: {
          text: 'There are no registered purchase orders.',
        }
      },
      warehouse: {
        textTitle: 'My warehouses',
        textAllWarehouses: 'See all my warehouses',
        textButtonView: 'View detail',
        empty: {
          text: 'There are no registered warehouses.',
          link: 'Agregar uno nuevo',
        }
      },
      article: {
        textTitle: 'My items',
        textAllArticles: 'See all my items',
        textCalifications: 'Califications',
        textBox: 'Box',
        textButtonView: 'View item',
        empty: {
          text: 'There are no registered items.',
          link: 'Add a new one'
        }
      },
    }
  },
  sp: {
    home: {
      isVerifiedText: 'Tu empresa no ha sido verificada, contacta a PROPLAT.',
      withoutWarehouse: 'Antes de crear un artículo, primero debe de registrar un almacén.',
      banner: {
        welcome: 'Bienvenido a PROPLAT',
        description: 'La plataforma donde puedes comerciar PRODUCE de forma directa, rápida, segura y sin intermediarios.'
      },
      purchaseOrder: {
        textTitle: 'Últimas órdenes de compra',
        textAllOrders: 'Ver todas las órdenes',
        empty: {
          text: 'No hay órdenes de compra registradas',
        }
      },
      warehouse: {
        textTitle: 'Mis almacenes',
        textAllWarehouses: 'Ver todos mis almacenes',
        textButtonView: 'Ver detalle',
        empty: {
          text: 'No hay almacenes registrados.',
          link: 'Agregar uno nuevo',
        }
      },
      article: {
        textTitle: 'Mis artículos',
        textAllArticles: 'Ver todos mis artículos',
        textCalifications: 'Calificaciones',
        textBox: 'Caja',
        textButtonView: 'Ver artículo',
        empty: {
          text: 'No hay artículos registrados.',
          link: 'Agregar uno nuevo'
        }
      },
    }
  }
};

export default texts;